import { Alert, Button, FormLabelGroup, Input } from '@appfolio/react-gears';
import { maybeReportException } from '@im-frontend/utils/errors';
import isEmbedded from '@im-frontend/utils/isEmbedded';
import * as api from 'api';
import PageContent from 'components/shared/PageContent';
import React, { useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as formHints from 'utils/formHints';

function defaultAlert(
  location: RouteComponentProps['location']
): React.ReactNode {
  switch (location.state?.alert) {
    case 'emailDuplicateKey':
      return 'That email address is already associated with an existing account. Sign In below to activate using your existing account.';
    case 'invitationAlreadyRedeemed':
      return 'That invitation has already been accepted. Sign In below to continue using Investment Manager.';
    case 'passwordResetFailed':
      return (
        <>
          Your password reset link has expired or has already been used. To
          reset your password, <Link to="/password/forgot">click here</Link>.
        </>
      );
    case undefined:
      return;
    default:
      throw new Error(`Unrecognized state.alert (${location.state?.alert})`);
  }
}

function defaultInfo(state: any): React.ReactNode {
  if (state?.passwordResetSent) {
    return 'We sent an email with instructions on how to reset your password.';
  }
}

function loginWithOkta(history: RouteComponentProps['history'], email: string) {
  history.push(`/auth/okta?login_hint=${encodeURIComponent(email)}`);
}

/**
 * Your everyday login screen.
 */
function New({ history, location }: RouteComponentProps) {
  const [email, setEmail] = useState(formHints.guessEmail(location));
  const [password, setPassword] = useState('');
  const [passwordFeedback, setPasswordFeedback] = useState('');
  const [alert, setAlert] = useState(defaultAlert(location));
  const [info, setInfo] = useState(defaultInfo(location.state));
  const isOkta =
    email &&
    email.toLowerCase().endsWith('@appfolio.com') &&
    !email.includes('+');

  const login = async (e: any) => {
    e.preventDefault();
    if (!password && !isOkta) {
      setPasswordFeedback('Please enter a password');
      return;
    }

    if (isOkta) {
      loginWithOkta(history, email);
      return;
    }
    try {
      const outcome = await api.v0.oauth2.token({
        grant_type: 'password',
        username: email,
        password,
      });

      if (api.v0.isGrant(outcome)) {
        history.push('/oauth2/continue');
      } else {
        switch (outcome.error) {
          case 'invalid_grant':
          case 'invalid_request':
            setAlert(
              'Sorry, the email or password you entered was not recognized.'
            );
            break;
          case 'pending_invite':
            setAlert(false); // Clear APM warning if present
            setInfo(
              <div>
                <p>
                  We upgraded our system to give you more control of the users
                  on your account. Due to this change your old password has
                  expired. An email was sent to you inviting you to activate
                  your upgraded account and create a new password.
                </p>
                <p>
                  If you can&apos;t find the welcome email, please contact our{' '}
                  <a href="mailto:support@appfolioinvestmentmanagement.com">
                    support team
                  </a>
                  .
                </p>
              </div>
            );
            break;
          default:
            setAlert('Sorry, something went wrong.');
        }
      }
    } catch (err) {
      setAlert('Sorry, something went wrong.');
      maybeReportException(err);
    }
    return false;
  };

  return (
    <PageContent size="sm" title="Sign In">
      {alert && <Alert color="danger">{alert}</Alert>}
      {info && <Alert color="info">{info}</Alert>}
      <form onSubmit={login}>
        <FormLabelGroup label="Email" stacked>
          <Input
            autoFocus
            defaultValue={email}
            autoComplete="username"
            onChange={(e: any) => setEmail(e.target.value)}
          />
        </FormLabelGroup>
        <FormLabelGroup label="Password" stacked feedback={passwordFeedback}>
          <Input
            defaultValue={password}
            autoComplete="current-password"
            disabled={isOkta}
            type="password"
            onChange={(e: any) => setPassword(e.target.value)}
          />
        </FormLabelGroup>
        <FormLabelGroup>
          <Button type="submit" className="my-2 py-3 w-100" color="primary">
            {isOkta ? 'Sign In With Okta' : 'Sign In'}
          </Button>
          <Link
            className="btn btn-link pl-0"
            to={{
              pathname: '/password/forgot',
              state: { formHints: { email } },
            }}
          >
            Forgot your password?
          </Link>
        </FormLabelGroup>
      </form>
    </PageContent>
  );
}

export default New;
